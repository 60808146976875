import { Link } from "react-router-dom";
import Logo from "../../../../assets/logo/logo-paycurra.svg";
import {
  FbIcon,
  IgIcon,
  // PinterestIcon,
  XIcon,
} from "../../../../assets/svg";
import { urlConfig } from "../../../../config";

export const Footer = () => {
  return (
    <footer className="bg-py_black-100">
      <div className="container py-14 flex flex-col !px-4 md:flex-row xl:gap-[180px] xl:py-20 xl:!px-0">
        <div className="flex flex-col gap-y-14 !order-1 mb-10 w-full md:w-5/12 md:!order-1 md:pt-0 lg:mb-0 xl:w-5/12">
          <Link to="/" className="inline-flex flex-col w-[180px] xl:w-[235px]">
            <img
              src={Logo}
              alt="Paycurra checkout logo"
              width={235}
              className="w-[180px] xl:w-[235px]"
            />
            <small className="ms-auto text-py_black-950 text-[1.15rem] mt-[-1.35rem] font-['Cabin'] md:text-[1.25rem] lg:mt-[-1.5rem] xl:text-[1.5rem]">
              Checkout
            </small>
          </Link>

          {/* <div className="">
            <div className="text-py_black-950 font-semibold mb-2">
              Download app:
            </div>
            <div className="inline-flex gap-2 flex-wrap flex-col xl:flex-row">
              <img
                src={GooglePlay}
                alt="Google play button"
                className="md:w-[150px] h-[48px] xl:w-[110px]"
              />
              <img
                src={AppleStore}
                alt="Apple store button"
                className="md:w-[150px] h-[48px] xl:w-[110px]"
              />
            </div>
          </div> */}
        </div>

        <div className="flex ml-auto flex-row flex-wrap order-1 w-full md:w-7/12 md:order-2 ml-0 xl:w-7/12">
          <ul className="inline-flex flex-col gap-y-3 text-py_black-950 w-1/2 mb-16 md:gap-y-4  xl:w-4/12 xl:mb-0">
            <li className="mb-4 text-[20px] font-semibold xl:text-[1.5rem]">
              <Link to="#">Products</Link>
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <a target="_blank" href={urlConfig.SITE_LINKS.merchant}>
                Merchant
              </a>
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <Link to={urlConfig.SITE_LINKS.checkout}>Checkout</Link>
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <a target="_blank" href={urlConfig.SITE_LINKS.marketplace}>
                Marketplace
              </a>
            </li>
            {/* <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              For individuals
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              For businesses
            </li> */}
          </ul>

          <ul className="inline-flex flex-col gap-y-3 text-py_black-950 w-1/2 md:gap-y-4  xl:w-4/12">
            <li className="mb-4 text-[20px] font-semibold xl:text-[1.5rem]">
              Company
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <Link to="#">About Paycurra</Link>
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <Link to="#">Features</Link>
            </li>
            {/* <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              Integration
            </li> */}
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <Link to="#">Policies</Link>
            </li>
            {/* <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              Careers
            </li> */}
          </ul>

          <ul className="inline-flex flex-col gap-y-3 text-py_black-950 w-1/2 md:gap-y-4  xl:w-4/12">
            <li className="mb-4 text-[20px] font-semibold xl:text-[1.5rem]">
              Support
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <Link to="#">Contact sales</Link>
            </li>
            {/* <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              Help center
            </li> */}
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <Link to="#">Privacy policy</Link>
            </li>
            <li className="tracking-[-0.32px] text-[0.875rem] xl:text-[1rem]">
              <Link to="#">Cookies</Link>
            </li>
            <li className="inline-flex gap-x-5 social_link_group">
              <a href="https://www.instagram.com/paycurra/" target="_blank">
                <IgIcon />
              </a>
              <a href="https://x.com/paycurra" target="_blank">
                <XIcon />
              </a>
              <a href="https://web.facebook.com/paycurra" target="_blank">
                <FbIcon />
              </a>
              {/* <Link to="/">
                <PinterestIcon />
              </Link> */}
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
