import { styled } from "@mui/material";

export const NavBox = styled("nav")(({ theme }) => ({
  position: "relative",
  background: "transparent",
  borderBottom: "1px solid #E6E6E7",
  overflowX: "hidden",

  "& .iconHolder": {
    position: "absolute",
    top: "0",
    right: "2rem",
    zIndex: 500000,
    display: "flex",
    marginLeft: "auto",
    cursor: "pointer",
    padding: "0.45rem",
    borderRadius: "50%",
  },

  "& .logo-link": {
    textDecoration: "none",

    "& small": {
      color: "#0F0F10",
      marginTop: "-0.75rem",
      fontFamily: "Cabin",
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },

  "& .profile-section": {
    display: "flex",
    alignItems: "center",

    "& .greet": {
      color: "#0F0F10",
      fontSize: "1rem",
      fontWeight: 400,
    },

    "& .profile-icon": {
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      backgroundColor: "#FFE1DF",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  "@media ((min-width: 1400px))": {
   "& .container": {
      maxWidth: '1385px'
    }
  }
}));
