import { FormControl, styled } from "@mui/material";

export const SelectInputBox = styled(FormControl)(({ theme }) => ({
  width: "100%",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    paddingLeft: "0 !important"
  },

  "& .feedback": {
    color: "#F44336",
    paddingBottom: theme.spacing(1.5),

    "& span": {
      fontSize: "0.85rem"
    }
  },

  "& .select__input": {
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary,
      borderWidth: 1
    },

    "& .MuiOutlinedInput": {
      paddingLeft: "0 !important"
    },

    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0.55rem 0.75rem",
      color: theme.palette.text
    },

    "& .MuiSelect-iconOutlined": {
      color: theme.palette.primary.main
    },

    "& legend": {
      display: "none"
    }
  }
}));
