import { styled } from "@mui/material";

export const RadioCardBox = styled("div")(({ theme }) => ({
  "& .selected": {
    border: "1px solid #FF5349",
    boxShadow: "2px 2px 8px 0px rgba(237, 33, 21, 0.20)",
  },

  "& .checkBtn": {
    width: "16px",
    height: "16px",
    minWidth: "16px",
    minHeight: "16px",
    borderRadius: "50%",
    border: "1px solid #C0BFBF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      height: "13px",
    },
  },

  "& .checkedBtn": {
    border: "none",
    backgroundColor: "#00AE97",
  },

  "& .card": {
    padding: "1rem",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    cursor: "pointer",
    transition: "0.3s all ease-in-out",
  },

  "& .title": {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#0F0F10",
  },

  "& .balance": {
    fontSize: "0.75rem",
    fontWeight: 500,
    color: "#696A71",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {},
}));
