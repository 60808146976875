import { WWalletIcon, CartIcon, TravelIcon } from "../../../assets/svg";
import { PyButton } from "../../../components";
import { WebsiteBaseLayout } from "../components/Layout/Base";
import HeroImg from "../../../assets/images/checkout-hero-bg.png";
import CheckoutFlowIcon from "../../../assets/images/optimized-checkoutf-icon.svg"
import CartValueIcon from "../../../assets/images/increase-cart-icon.svg"
import HigherConversionIcon from "../../../assets/images/higher-conversion-icon.svg"
import CaartValueIcon from "../../../assets/images/increase-cart-icon.svg"

export const LandingPage = () => {
  return (
    <WebsiteBaseLayout>
      <header className="h-full !py-16 bg-white lg:h-[600px]">
        <div className="container flex h-full flex-wrap !px-5 md:!px-11 lg:!px-16">
          <div className="w-full flex items-center mb-12 lg:mb-0 lg:w-1/2">
            <div>
              <h2 className="text-py_black-950 text-[2rem] font-semibold leading-[110%] tracking-[-1.28px] md:text-[2.5rem] tracking-[-2.08px] lg:tracking-[-2.24px]">
                Maximize conversions, minimize cart abandonment, and increase
                cart value
              </h2>
              <p className="text-py_black-700 text-[0.875rem] font-normal leading-[120%] my-6 md:text-[1rem] lg:mr-30">
                Start rewarding customers and accepting card, gift card, stamps
                or point-based payment options using our web checkout SDK
              </p>

              <div className="flex !gap-5">
                <PyButton>Try Checkout SDK</PyButton>

                <PyButton extraClass="!border-0" variant="Secondary">
                  Book a demo
                </PyButton>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center md:justify-center lg:w-1/2 justify-end">
            <img
              src={HeroImg}
              width={590}
              height={360}
              className="rounded-4 object-cover"
              alt="Maximize conversions, minimize cart abandonment, and increase
              cart value"
            />
          </div>
        </div>
      </header>

      <main>
        <section id="features" className="!py-20 bg-white">
          <div className="container !px-5 md:px-11 lg:!px-16">
            <div className="w-full mx-auto text-center mb-14 lg:w-[70%]">
              <h4 className="text-py_black-950 text-[1.35rem] font-semibold leading-[110%] tracking-[-0.8px] !mb-5 md:text-[2rem] lg:text-[2.25rem] xl:w-9/12 xl:mx-auto">
                A seamless checkout solution built to enable accelerated
                business growth
              </h4>
              <p className="text-py_black-700 text-[0.875rem] font-normal leading-[120%] lg:text-[1rem] xl:w-9/12 xl:mx-auto">
                Checkout solution integrated with your promotions and loyalty
                offerings to incentivise your customers and provide more ways to
                pay
              </p>
            </div>

            <div className="flex flex-wrap !gap-5">
              <div className="h-[210px] flex flex-col justify-between border border-py_black-950 !py-10 !px-6 w-[calc((100%/2)-(20px/2))] lg:w-[calc((100%/4)-(60px/4))] lg:min-h-[270px]">
                <div className=""></div>
                <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                  Optimised checkout flow
                </h6>
              </div>

              <div className="h-[210px] flex flex-col justify-between border border-py_black-950 !py-10 !px-6 w-[calc((100%/2)-(20px/2))] lg:w-[calc((100%/4)-(60px/4))] lg:min-h-[270px]">
                <div className=""></div>
                <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                Increase cart value
                </h6>
              </div>

              <div className="h-[210px] flex flex-col justify-between border border-py_black-950 !py-10 !px-6 w-[calc((100%/2)-(20px/2))] lg:w-[calc((100%/4)-(60px/4))] lg:min-h-[270px]">
                <div className=""></div>
                <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                Higher conversion
                </h6>
              </div>

              <div className="h-[210px] flex flex-col justify-between border border-py_black-950 !py-10 !px-6 w-[calc((100%/2)-(20px/2))] lg:w-[calc((100%/4)-(60px/4))] lg:min-h-[270px]">
                <div className=""></div>
                <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                Multiple payment gateways
                </h6>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-py_black-950 py-20">
          <div className="container !px-5 md:!px-11 lg:!px-16">
            <div className="w-full mx-auto text-center mb-14 lg:w-[70%]">
              <h4 className="text-py_black-100 text-[1.5rem] font-semibold leading-[110%] tracking-[-0.8px] mb-6 md:text-[2rem] lg:text-[2.25rem] xl:w-9/12 xl:mx-auto">
                Build customer engagement experiences that are tailored to your
                industry
              </h4>
              <p className="text-white text-[0.875rem] font-normal leading-[120%] md:text-[1rem] xl:w-10/12 xl:mx-auto">
                Whether you are a fintech insurgent, an incumbent bank or a
                retail store, integrate with the Paycurra Commerce Platform
                today for solutions to help you attract and retain customers
                with personalised offerings.
              </p>
            </div>

            <div className="flex flex-wrap !gap-5">
              <div className="w-full rounded-[1rem] bg-primary-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-primary-300">
                  <CartIcon />
                </div>

                <div className=""></div>
                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Ecommerce and Retail
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Give rewards on first visit and on birthdays, incentivise
                  based on cart attributes, increase order value with realtime
                  recommendations and use gamification to drive repeat
                  behaviours.
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_green_2-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_green_2-300">
                  <WWalletIcon />
                </div>
                <div className=""></div>
                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Banks and Fintechs
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Award customers points based on account balance, transaction
                  attributes, or card usage. And create winning Cashback loyalty
                  programmes to incentivise targeted payment options (e.g.
                  BNPL).
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_blue-100 py-5 px-6  lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_blue-300">
                  <TravelIcon />
                </div>

                <div className=""></div>
                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Travel and Hospitality
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Increase repeat business with a tiered system targeting
                  diverse customer segments. Build rules offering free hotel
                  stays or collaborate with partners in travel industry for
                  flight class upgrades.
                </p>
              </div>
            </div>

            <div className="flex justify-center !gap-5 mt-14">
              <PyButton>Get started</PyButton>
              <PyButton
                extraClass="!text-white !border-white"
                variant="Secondary"
              >
                Book a demo
              </PyButton>
            </div>
          </div>
        </section>
      </main>
    </WebsiteBaseLayout>
  );
};
