import { styled } from "@mui/material";

export const WalletCheckoutBox = styled("div")(({ theme }) => ({
  margin: "0 auto 2rem",
  position: "relative",
  height: "100%",
  minHeight: "100vh",

  "& .col-container": {
    padding: "56px 40px",
    borderRight: "1px solid #E6E6E7",
    borderLeft: "1px solid #E6E6E7",
  },

  "& .header": {
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    "& .title": {
      color: "#0F0F10",
      fontSize: "1.25rem",
      fontWeight: 500,
    },

    "&  .details": {
      "& strong": {
        color: "#0F0F10",
      },

      color: "#4C4D52",
      fontSize: "1rem",
      fontWeight: 400,
    },
  },

  "& .info": {
    color: "#84858C",
    fontWeight: 500,
    fontSize: "0.625rem",
  },

  "& .addPaymentContainer": {
    display: "inline-flex",
    alignItems: "center",
    gap: "0 0.4rem",
    color: "#00AE97",
    cursor: "pointer",
    marginBottom: "1.5rem",

    "& .text": {
      fontSize: "0.75rem",
      fontWeight: 500,
    },

    "& .remove": {
      color: "#C8170D",
    },
  },

  "& .iframeContainer": {
    position: "fixed",
    zIndex: "20",
    top: 0,
    right: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(229, 229, 229, 0.38)",
    backdropFilter: "blur(2px)",
  },

  "& .closeLoginIframe": {},

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {
    "& .col-container": {
      padding: "24px 16px",
      borderRight: "none",
      borderLeft: "none",
    },

    "& .header": {
      width: "98%",
      textAlign: "center",

      "& .title": {
        fontSize: "1.05rem",
      },

      "&  .details": {
        fontSize: "0.875rem",
        margin: "0 0.25rem",
      },
    },
  },
}));
