import { styled } from "@mui/material";

export const CardDetailsBox = styled("div")(({ theme }) => ({
  "& ul": {
    padding: "1rem 0.5rem",
    border: "1px solid #E6E6E7",
    borderRadius: "8px",

    "& li": {
      display: "flex",
      justifyContent: "space-between",
      padding: "0.875rem 1rem",
      fontSize: "0.875rem",

      "&:last-child": {
        paddingBottom: "0",
      },

      "& .title": {
        color: "#0F0F10",
        fontWeight: 500,
      },

      "& .details": {
        color: "#ADAEB3",
        fontWeight: 400,
      },
    },
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {},
}));
