import { WebsiteBaseLayout } from "../components/Layout/Base";
import { PyButton } from "../../../components";
import { WWalletIcon, CartIcon, TravelIcon } from "../../../assets/svg";

export const DeveloperTools = () => {
  return (
    <WebsiteBaseLayout>
      <header className="h-full py-16 border-b border-py_black-100 bg-white lg:py-30">
        <div className="container flex h-full flex-wrap !px-5 md:!px-11 lg:!px-16">
          <div className="w-full flex items-center justify-center mb-12">
            <div className="text-center mx-auto lg:w-7/12">
              <h2 className="text-py_black-950 font-semibold text-[2rem] leading-[110%] tracking-[-1px] mb-5 lg:text-[3.125rem]">
                Developers Tools
              </h2>
              <p className="mb-12 text-py_black-700 font-normal lg:text-[1.25rem] xl:w-10/12 xl:mx-auto">
                We offer free integration consultation, technical guidance, and
                implementation to help you get started without any additional
                cost.
              </p>
              <PyButton>Contact Sales</PyButton>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section className="py-20 bg-white">
          <div className="container !px-5 flex flex-col gap-10 md:!px-11 lg:gap-20 lg:p!x-16">
            <div className="rounded-[1rem] flex flex-wrap p-6 bg-primary-50 lg:p-14">
              <div className="w-full flex items-center lg:w-1/2">
                <div>
                  <h4 className="text-py_black-950 mb-6 text-[2rem] font-semibold tracking-[-0.64px] leading-[120%] lg:w-8/12">
                    Choose your preferred payment gateway
                  </h4>
                  <p className="text-py_black-700 text-[0.875rem] font-normal leading-[120%] lg:w-10/12">
                    Our platform offers seamless integration through low-code
                    solutions and supports popular gateways like Paystack,
                    Stripe, and others, ensuring you can integrate without being
                    tied to a single option.
                  </p>
                </div>
              </div>
              <div className="w-full h-[320px] bg-white lg:w-1/2"></div>
            </div>

            <div className="rounded-[1rem] flex flex-wrap p-6 bg-py_green_2-50 lg:p-14">
              <div className="w-full flex items-center lg:w-1/2">
                <div>
                  <h4 className="text-py_black-950 mb-6 text-[2rem] font-semibold tracking-[-0.64px] leading-[120%] lg:w-8/12">
                    Boost Sales with Instant Promotions on the Paycurra Wallet
                    App
                  </h4>
                  <p className="text-py_black-700 text-[0.875rem] font-normal leading-[120%] lg:w-10/12">
                    Unlock the power of our platform as your promotions go live
                    and become instantly accessible on the Paycurra Wallet app.
                    Users can conveniently reserve deals and rewards, ensuring
                    savings or earnings with every purchase made through
                    Paycurra on your integrated website.
                  </p>
                </div>
              </div>
              <div className="w-full h-[320px] bg-white lg:w-1/2"></div>
            </div>

            <div className="rounded-[1rem] flex flex-wrap p-6 bg-py_green_1-50 lg:p-14">
              <div className="w-full flex items-center lg:w-1/2">
                <div>
                  <h4 className="text-py_black-950 mb-6 text-[2rem] font-semibold tracking-[-0.64px] leading-[120%] lg:w-8/12">
                    Choose your preferred payment gateway
                  </h4>
                  <p className="text-py_black-700 text-[0.875rem] font-normal leading-[120%] lg:w-10/12">
                    Our platform offers seamless integration through low-code
                    solutions and supports popular gateways like Paystack,
                    Stripe, and others, ensuring you can integrate without being
                    tied to a single option.
                  </p>
                </div>
              </div>
              <div className="w-full h-[320px] bg-white lg:w-1/2"></div>
            </div>
          </div>
        </section>

        <section className="bg-py_black-950 py-20">
          <div className="container !px-5 md:!px-11 lg:!px-16">
            <div className="w-full mx-auto text-center mb-14 lg:w-[70%]">
              <h4 className="text-py_black-100 text-[2.25rem] font-semibold leading-[110%] tracking-[-0.8px] mb-6 xl:w-9/12 xl:mx-auto">
                Build customer engagement experiences that are tailored to your
                industry
              </h4>
              <p className="text-white text-[1rem] font-normal leading-[120%] xl:w-10/12 xl:mx-auto">
                Whether you are a fintech insurgent, an incumbent bank or a
                retail store, integrate with the Paycurra Commerce Platform
                today for solutions to help you attract and retain customers
                with personalised offerings.
              </p>
            </div>

            <div className="flex flex-wrap !gap-5">
              <div className="w-full rounded-[1rem] bg-primary-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-primary-300">
                  <CartIcon />
                </div>

                <h6 className="text-[1.125rem] text-py_black-950 !mb-3 font-medium">
                  Ecommerce and Retail
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Give rewards on first visit and on birthdays, incentivise
                  based on cart attributes, increase order value with realtime
                  recommendations and use gamification to drive repeat
                  behaviours.
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_green_2-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_green_2-300">
                  <WWalletIcon />
                </div>
                <h6 className="text-[1.125rem] text-py_black-950 !mb-3 font-medium">
                  Banks and Fintechs
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Award customers points based on account balance, transaction
                  attributes, or card usage. And create winning Cashback loyalty
                  programmes to incentivise targeted payment options (e.g.
                  BNPL).
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_blue-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_blue-300">
                  <TravelIcon />
                </div>

                <h6 className="text-[1.125rem] text-py_black-950 !mb-3 font-medium">
                  Travel and Hospitality
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Increase repeat business with a tiered system targeting
                  diverse customer segments. Build rules offering free hotel
                  stays or collaborate with partners in travel industry for
                  flight class upgrades.
                </p>
              </div>
            </div>

            <div className="flex justify-center !gap-5 mt-14">
              <PyButton>Get started</PyButton>
              <PyButton
                extraClass="!text-white !border-white"
                variant="Secondary"
              >
                Book a demo
              </PyButton>
            </div>
          </div>
        </section>
      </main>
    </WebsiteBaseLayout>
  );
};
