import * as React from "react";
import { OrderBox } from "./OrderSummary.style";
import { PCButton, ToastMessage, TOASTER_TYPE } from "../../../../components";
import {
  calculateTotalPrice,
  convertFromNairaToKobo,
  convertPriceToNaira,
} from "../../../../utils/helper";
import { checkoutToPay } from "../../../../services/api";
import { NumericFormat } from "react-number-format";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { RewardRuleEffects } from "../../../../types/index";
import { PyButton } from "../../../../components";
import { useMutation } from "@tanstack/react-query";
import { ICartItem } from "../../../../types/interfaces";
import { applyRewardToTotalCart } from "../../../../services/applyReward.service";
import { GiftBagIcon } from "../../../../assets/svg";

interface IOrderProps {
  totalCost: number | any;
  subTotal: number | string | any;
  isCardSelected: boolean;
  pointData: any;
  rewardData: any;
  cardDetails: any;
  gateWayDetails: any;
  updatedCartTotal: (a: number) => any;
  rawCartTotal: number;
  cartItems: ICartItem[];
  updateCartItems: any;
}

export const OrderSummary: React.FC<IOrderProps> = ({
  totalCost,
  subTotal,
  isCardSelected,
  pointData,
  rewardData,
  cardDetails,
  gateWayDetails,
  updatedCartTotal,
  rawCartTotal,
  cartItems,
  updateCartItems,
}) => {
  const navigate = useNavigate();
  const [updatedCartItemTotal, setUpdatedCartItemTotal] =
    React.useState<any>(null);
  const [bookings, setBookings] = React.useState<any[] | null>(null);
  const [shipping, setShipping] = React.useState<any>(null);
  const onCheckoutToPay = useMutation({
    mutationKey: ["CheckoutAndPay"],
    mutationFn: checkoutToPay,
    onSuccess: (data: any) => {
      // console.log(data);
      if (data?.response?.status === 409) {
        ToastMessage(
          TOASTER_TYPE.ERROR,
          "Sorry, you have checkout with this session. Clear your cart and retry"
        );
        return;
      }

      if (data?.response?.status === 400) {
        ToastMessage(
          TOASTER_TYPE.ERROR,
          "An error occurred, please contact support"
        );
        return;
      }

      if (data.status === 200 || data.status === 201) {
        // If stock allocation fails
        if (!data?.data?.status) {
          if (!data?.data?.data?.success) {
            if (!data?.data?.data?.allocationStockResult?.success) {
              ToastMessage(
                TOASTER_TYPE.ERROR,
                "Some items are low on stock, please reduce the quantity and try again"
              );
              updateCartItemOutOfStock(
                data?.data?.data?.allocationStockResult.data
              );
            }
          }
        } else {
          localStorage.setItem("c_status", "true");

          navigate("/payment-success", {
            replace: true,
            state: {
              paymentDetails: data.data.data,
              gateWayDetails,
              total: rawCartTotal + convertFromNairaToKobo(shipping.charge), // add booking later
            },
          });
        }
      }
    },
  });

  React.useEffect(() => {
    setUpdatedCartItemTotal(handleUpdateCartTotal());
  }, [pointData.value]);

  React.useEffect(() => {
    const session_data = JSON.parse(
      localStorage.getItem("session_data") as string
    );

    setShipping(session_data.shipping);
    setBookings(session_data.bookings);
  }, []);

  const totalBookingCharge = (all_bookings: any[]) => {
    const result = all_bookings.reduce(
      (accumulator: number, currentValue: any) => {
        return accumulator + currentValue.charge;
      },
      0
    );

    return result;
  };

  const handleUpdateCartTotal = () => {
    const calculatedTotal: number = calculateTotalPrice(
      totalCost,
      pointData.value,
      rewardData.onCartTotal.price
    );

    // Updating calculated cart total amount in Parent (Wallet)
    updatedCartTotal(calculatedTotal);

    return calculatedTotal;
  };

  const updateCartItemOutOfStock = (outOfStockItems: any[]) => {
    const updatedCart = cartItems.map((item) => {
      outOfStockItems.map((lowStockItem: any) => {
        if (item.sku === lowStockItem.sku)
          item = {
            ...item,
            isOutOfStock: !lowStockItem.sufficientStockExists,
            availableStockQuantity: lowStockItem.availableStockQuantity,
          };
        console.log(item);
      });
      return item;
    });

    // console.log(updatedCart);

    updateCartItems(updatedCart);
  };

  const getShippingReward = (rewards: any[]) => {
    if (rewards && rewards.length < 1) return [];
    return rewards.filter((reward) => reward.rewardEffect === RewardRuleEffects.FreeShipping)
  }

  const freeShippingReward = (getShippingReward(rewardData?.allRewards) && getShippingReward(rewardData?.allRewards).length >= 1)
    && applyRewardToTotalCart(
      getShippingReward(rewardData?.allRewards)[0],
      rawCartTotal
    )

  const bookingsCharge = bookings ? totalBookingCharge(bookings) : 0;
  const shippingCharge = shipping
    ? freeShippingReward?.price === 0 ? 0 : shipping?.charge
    : 0;

  const renderButton = (amount: number) => {
    amount = amount < 0 ? 0 : amount;

    let disableStatus: boolean = true;
    const total = calculateTotalPrice(
      totalCost,
      pointData.value,
      rewardData.onCartTotal.price
    );

    // console.log(isCardSelected, cardDetails)

    if (isCardSelected && cardDetails) {
      disableStatus = false;
    } else if (pointData.value !== "" && total <= 0) {
      disableStatus = false;
    } else {
    }

    return (
      <>
        <PyButton
          isDisabled={disableStatus || onCheckoutToPay.isLoading}
          click={() => handleCheckoutToPay(amount)}
          loading={onCheckoutToPay.isLoading}
        >
          <NumericFormat
            value={convertPriceToNaira(
              amount + bookingsCharge + (shippingCharge)
            )}
            displayType={"text"}
            thousandSeparator={true}
            prefix={`Pay ${gateWayDetails?.currencySymbol}`}
          />
        </PyButton>
      </>
    );
  };

  const handleCheckoutToPay = async (amount: number) => {
    const sessionData = JSON.parse(
      localStorage.getItem("session_data") as string
    );

    const pointPayload = {
      loyaltyProgrammeUniqueReferenceNumber: pointData?.userPointDetail?.loyaltyProgrammeUniqueReferenceNumber || pointData?.userPointDetail?.id.loyaltyProgrammeUniqueReferenceNumber,
      loyaltyMemberUniqueReferenceNumber: pointData?.userPointDetail?.loyaltyMemberUniqueReferenceNumber || pointData?.userPointDetail?.id?.loyaltyProgrammeMemberUniqueReferenceNumber,
      loyaltyProgrammeTierUniqueReferenceNumber: pointData?.userPointDetail?.id.loyaltyProgrammeTierUniqueReferenceNumber,
      points: (parseInt(pointData?.value)),
    };

    const cardPayload = {
      authorizationCode: cardDetails?.authorizationCode,
      customerId: cardDetails?.gatewayCustomerId,
      amount: amount + (freeShippingReward ? 0 : shipping.charge) + (bookings && bookings.length >= 1 ? totalBookingCharge(bookings) : 0), // , amount in kobo
      emailAddress: cardDetails?.emailAddress,
      currency: gateWayDetails.paymentGatewayType === "STRIPE" ? "GBP" : "NGN", // make GBP for stripe
      paymentMethod: "card",
      cardTokenUniqueReferenceNumber: cardDetails?.uniqueReferenceNumber,
      paymentUniqueReferenceNumber: uuidv4(), // generate for everytime payment is processed
      paymentItemUniqueReferenceNumber: sessionData?.uniqueReferenceNumber, // attached to a session
    };

    let rewardPayload: any = {
      uniqueReferenceNumber: rewardData?.selectedReward?.uniqueReferenceNumber,
      discountAmountApplied: rewardData?.selectedReward?.discountAmountApplied, // converted to kobo
    };

    let shippingRewardRedemption = {
      uniqueReferenceNumber: freeShippingReward?.rewardDetails?.uniqueReferenceNumber,
      discountAmountApplied: shipping.charge,
      promotionUniqueReferenceNumber: freeShippingReward?.rewardDetails?.promotionUniqueReferenceNumber || undefined,
      loyaltyProgrammeTierUniqueReferenceNumber: freeShippingReward?.rewardDetails?.loyaltyProgrammeTierUniqueReferenceNumber || undefined
    }

    if (rewardData?.selectedReward?.promotionUniqueReferenceNumber) {
      rewardPayload = {
        ...rewardPayload,
        promotionUniqueReferenceNumber:
          rewardData?.selectedReward?.promotionUniqueReferenceNumber,
      };
    } else {
      rewardPayload = {
        ...rewardPayload,
        loyaltyProgrammeTierUniqueReferenceNumber:
          rewardData?.selectedReward?.loyaltyProgrammeTierUniqueReferenceNumber,
      };
    }

    let payload = {
      pointsRedemption: pointData.value >= 1 ? pointPayload : undefined,
      recurrentBilling: isCardSelected ? cardPayload : undefined,
      rewardRedemption: rewardData.isRewardSelected ? rewardPayload : undefined,
      shippingRewardRedemption: freeShippingReward ? shippingRewardRedemption : undefined,
      includesStockAllocation: true,
      shipping: {
        ...shipping,
        charge: shipping.charge,
      },
      bookings:
        bookings && bookings.length >= 1 ? (bookings as any[]) : undefined,
      deliveryNote: sessionData?.deliveryNote || "",
    };

    // Intuitively detect items not selected to pay(checkout)
    if (payload.pointsRedemption === undefined) {
      delete payload.pointsRedemption;
    }

    if (payload.recurrentBilling === undefined) {
      delete payload.recurrentBilling;
    }

    if (payload.rewardRedemption === undefined) {
      delete payload.rewardRedemption;
    }

    if (payload.shippingRewardRedemption === undefined) {
      delete payload.shippingRewardRedemption;
    }

    onCheckoutToPay.mutate({ ...payload } as any);
  };



  // console.log("freeShippingReward", freeShippingReward)

  return (
    <OrderBox>
      <ul className="!mb-5">
        <li>
          <div className="key">Subtotal</div>
          <div className="value">{subTotal}</div>
        </li>

        {pointData.check && (
          <li>
            <div className="key">Loyalty points value</div>
            <div className="value">
              {pointData.check && pointData.value ? (
                <NumericFormat
                  value={pointData.value}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={gateWayDetails?.currencySymbol}
                />
              ) : (
                `${gateWayDetails?.currencySymbol}0`
              )}
            </div>
          </li>
        )}

        {shipping && (
          <li>
            <div className="inline-flex flex-col gap-1">
              <div className="key">
                Shipping fee
              </div>

              {freeShippingReward?.price === 0 && (
                <div className="inline-flex items-center !gap-1 rounded-[100px] bg-py_green_1-300 !py-[0.25rem] !px-2 w-fit">
                  <GiftBagIcon />

                  <div className="text-[0.75rem] text-py_black-950 font-medium">
                    Free shipping
                  </div>
                </div>
              )}
            </div>

            <div className="value">
              {freeShippingReward?.price === 0
                ? <NumericFormat
                  value={0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={gateWayDetails?.currencySymbol}
                />
                : <NumericFormat
                  value={convertPriceToNaira(shipping?.charge)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={gateWayDetails?.currencySymbol}
                />
              }
            </div>
          </li>
        )}

        {bookings && bookings.length >= 1 && (bookings as any[])[0].charge && (
          <li>
            <div className="key">Consultation booking fee</div>
            <div className="value">
              <NumericFormat
                value={convertPriceToNaira(totalBookingCharge(bookings))}
                displayType={"text"}
                thousandSeparator={true}
                prefix={gateWayDetails?.currencySymbol}
              />
            </div>
          </li>
        )}

        {rewardData.onCartTotal.price !== 0 && (
          <li>
            {/* <div className="key">
              Reward applied for {rewardData.onCartTotal.percentOff}% off{" "}
            </div> */}
            <div className="rewardAppliedBadge">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 3.04688V4.6875H9.14063C9.46511 4.6875 9.78231 4.59128 10.0521 4.41101C10.3219 4.23073 10.5322 3.9745 10.6564 3.67472C10.7805 3.37493 10.813 3.04506 10.7497 2.72681C10.6864 2.40856 10.5302 2.11622 10.3007 1.88678C10.0713 1.65733 9.77895 1.50108 9.4607 1.43777C9.14245 1.37447 8.81257 1.40696 8.51279 1.53114C8.213 1.65531 7.95677 1.86559 7.7765 2.13539C7.59622 2.40519 7.5 2.72239 7.5 3.04688V3.04688ZM7.5 3.04688V4.6875H5.85938C5.53489 4.6875 5.21769 4.59128 4.94789 4.41101C4.67809 4.23073 4.46781 3.9745 4.34364 3.67472C4.21946 3.37493 4.18697 3.04506 4.25027 2.72681C4.31358 2.40856 4.46983 2.11622 4.69928 1.88678C4.92872 1.65733 5.22106 1.50108 5.53931 1.43777C5.85756 1.37447 6.18743 1.40696 6.48722 1.53114C6.787 1.65531 7.04323 1.86559 7.22351 2.13539C7.40378 2.40519 7.5 2.72239 7.5 3.04688V3.04688Z"
                  stroke="#00AE97"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  d="M12.1875 4.6875H2.8125C2.29473 4.6875 1.875 5.10723 1.875 5.625V7.03125C1.875 7.54902 2.29473 7.96875 2.8125 7.96875H12.1875C12.7053 7.96875 13.125 7.54902 13.125 7.03125V5.625C13.125 5.10723 12.7053 4.6875 12.1875 4.6875Z"
                  stroke="#00AE97"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.1875 7.96875V12.1875C12.1875 12.5605 12.0393 12.9181 11.7756 13.1819C11.5119 13.4456 11.1542 13.5937 10.7812 13.5938H4.21875C3.84579 13.5937 3.4881 13.4456 3.22438 13.1819C2.96066 12.9181 2.8125 12.5605 2.8125 12.1875V7.96875M7.5 4.6875V13.5938"
                  stroke="#00AE97"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <> SaveNMore - [ {rewardData.onCartTotal.percentOff}% off ] </>
            </div>

            <div className="value">
              <NumericFormat
                value={convertPriceToNaira(rewardData.onCartTotal.price)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={gateWayDetails?.currencySymbol}
              />
              {/* &#8358;{reward.price} */}
            </div>
          </li>
        )}
        {/* <li>
          <div className="key">Reward applied for buy 2 get 1 free </div>
          <div className="value">NGN 10,000</div>
        </li> */}
        <li className="mt-3">
          <div className="key total">Grand Total</div>
          {gateWayDetails && (
            <div className="value total">
              <NumericFormat
                value={convertPriceToNaira(
                  handleUpdateCartTotal() + bookingsCharge + shippingCharge
                )}
                displayType={"text"}
                thousandSeparator={true}
                prefix={gateWayDetails?.currencySymbol}
              />
            </div>
          )}
        </li>
      </ul>

      <> {renderButton(handleUpdateCartTotal())}</>
    </OrderBox>
  );
};
