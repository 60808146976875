import * as React from "react";
import { EarnedPointBox } from "./EarnedPoint.style";
import { RewardRuleEffects } from "../../../../types";
import { DarkNewGiftIcon } from "../../../../assets/svg";

interface IProps {
  cartItemEarnable: any[];
  cartTotalEarnable: any[];
}

export const EarnedPoint: React.FC<IProps> = ({
  cartItemEarnable,
  cartTotalEarnable,
}) => {
  return (
    <EarnedPointBox>
      {[...cartItemEarnable, ...cartTotalEarnable].map(
        (reward: any, id: number) => (
          <div className="text-sm text-py_black-950 font-medium py-[6px] px-2 rounded-[100px] inline-flex items-center gap-2 bg-py_green_1-300" key={id}>
            <DarkNewGiftIcon />

            {reward.rewardEffect === RewardRuleEffects.AddPoints && <> {reward.rewardEffectValue} points </>}
            {reward.rewardEffect === RewardRuleEffects.AddDiscount && <> {reward.rewardEffectValue}% Off </>}
            {reward.rewardEffect === RewardRuleEffects.AddFreeItem && <> Get 1 free </>}
          </div>
        )
      )}
    </EarnedPointBox>
  );
};
