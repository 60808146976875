import { urlConfig } from "./url.config";
import axios from "axios";
import { decodeToken } from "../utils/helper";

let axiosInstance: any;

export const apiHeaders = {
  "Content-Type": "application/json",
  "Ocp-Apim-Subscription-Key": urlConfig.SUBSCRIPTION_KEY,
};

export const AxiosInstanceSetUp = () => {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: urlConfig.BASE_URL,
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": urlConfig.SUBSCRIPTION_KEY,
      },
    });
  }

  return axiosInstance;
};

export const setUpHeaders = (token: any) => {
  const decodedToken = decodeToken(token);
  console.log("decodedToken", decodedToken);

  if (token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    axiosInstance.defaults.headers.common["X-PYC-UserName"] = decodedToken.name;
    axiosInstance.defaults.headers.common["X-PYC-UserURN"] =
      decodedToken.extension_UserURN;
    // console.log(axiosInstance, "axios instance")
  }
};

export const getSessionData = () => {
  let session_data;
  try {
    session_data = JSON.parse(localStorage.getItem("session_data") as string);
  } catch (e) {
    console.log(e);
  }

  return session_data;
};

export const setSessionData = (data: any) => {
  localStorage.setItem("session_data", JSON.stringify(data));
};
