import { styled } from "@mui/material";

export const FooterBox = styled("footer")(({ theme }) => ({
  padding: "40px 0",
  background: "#F5F5F6",
//   position: "absolute",
  bottom: 0,
  width: "100%",
//   height: "0px",

  "& .textHolder": {
    textAlign: "center",
    padding: "0 0 2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& h6": {
      fontSize: "0.875rem",
      fontWeight: 600,
      color: "#847F7F",
      marginLeft: "0.25rem",
    },
  },

  "& .secured": {
    color: "#5A5B60",
  },

  "& .logo-link": {
    textDecoration: "none",

    "& small": {
      color: "#0F0F10",
      fontSize: "11px",
      marginTop: "-0.75rem",
    },
  },

  "& .copy": {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#5A5B60",
    display: "flex",
    alignIItems: "center",
    justifyContent: "center",
  },

  "@media (max-width:1199.9px)": {
    padding: "1rem !important",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:991.9px)": {},
}));
