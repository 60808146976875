import { Select, SelectProps, MenuItem, FormHelperText } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import React from "react";
import { SelectInputBox } from "./select.styles";
import { ChevronArrowDownIcon } from "../../../assets/svg";
import { useField } from "formik";

interface SelectItemsProps {
  value: string;
}

interface LSelectProps extends SelectProps {
  name: string;
  fullWidth?: boolean;
  hasObjects?: boolean;
  selectItems?: string[];
  helperText?: string | false;
  selectInputProps?: SelectInputProps;
  selectableObjects?: SelectItemsProps[] | any[];
  useValue?: boolean;
}

export const PySelect: React.FC<LSelectProps> = ({
  name,
  hasObjects,
  helperText,
  selectItems,
  selectableObjects,
  useValue = true,
  fullWidth = true,
  ...props
}) => {
    const [field, meta] = useField(name);

  return (
    <SelectInputBox fullWidth={fullWidth}>
      <label className="text-py_black-950 text-[14px] font-medium block mb-2">
        {props.label}
      </label>

      <Select
        sx={{
          "& .MuiList-root": {
            borderRadius: "0.5rem !important",
            padding: "0 !important"
          },

          "& svg": { marginRight: "1rem !important" }
        }}
        className="select__input"
        IconComponent={ChevronArrowDownIcon}
        {...props}
        {...field}
      >
        {props.placeholder && (
          <MenuItem
            sx={{
              padding: "0.875rem 1.25rem",
              color: "#787878",
              fontWeight: 600,
              fontSize: "0.875rem",

              "&:hover": {
                color: "#0C0C0C",
                background: "#E2EDFE"
              }
            }}
            disabled
            value=""
            selected>
            {props.placeholder}
          </MenuItem>
        )}
        {hasObjects
          ? selectableObjects?.map((item, idx) => (
            <MenuItem
              sx={{
                padding: "0.875rem 1.25rem",
                color: "#787878",
                fontWeight: 600,
                fontSize: "0.875rem",

                "&:hover": {
                  color: "#0C0C0C",
                  background: "#E2EDFE"
                }
              }}
              value={useValue ? item.value : item.name}
              key={idx}>
              {item.name}
            </MenuItem>
          ))
          : selectItems?.map((item, idx) => (
            <MenuItem
              sx={{
                padding: "0.875rem 1.25rem",
                color: "#787878",
                fontWeight: 600,
                fontSize: "0.875rem",

                "&:hover": {
                  color: "#0C0C0C",
                  background: "#E2EDFE"
                }
              }}
              value={item}
              key={idx}>
              {item}
            </MenuItem>
          ))}
      </Select>

      {meta.touched && meta.error && (
        <FormHelperText className={"feedback"}>{meta.error}</FormHelperText>
      )}
    </SelectInputBox>
  );
};
