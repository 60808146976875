import * as React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../pages/Login/Login";
import WalletCheckout from "../pages/WalletCheckout/WalletCheckout";
// import { NavBar } from "../components";
import LoginComplete from "../pages/B2C-Login/LoginComplete";
import B2CLogin from "../pages/B2C-Login/B2c-login";
import { PaymentSuccess } from "../pages/PaymentSuccess";
import { LandingPage } from "../pages/Website/Home/Home";
import { Pricing } from "../pages/Website/Pricing/Pricing";
import { DeveloperTools } from "../pages/Website/DeveloperTools/DeveloperTools";
import { VerifyPayment } from "../pages/VerifyPayment";

const MainROutes: React.FC<any> = ({ isQueryString }) => {
  return (
    <React.Fragment>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/b2cLogin" element={<B2CLogin />} />
        <Route path="/loginComplete" element={<LoginComplete />} />
        <Route path="/logout" element={<LoginComplete />} />

        <Route
          path="/"
          element={isQueryString ? <WalletCheckout /> : <LandingPage />}
        />
        <Route path="verify-payment" element={<VerifyPayment />} />
        <Route path="payment-success" element={<PaymentSuccess />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="developer-tools" element={<DeveloperTools />} />

        <Route path="*" element={"Page Not Found... "} />
      </Routes>
    </React.Fragment>
  );
};

export default MainROutes;
