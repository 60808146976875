import { Accordion, styled } from "@mui/material";

export const TAccordionBox = styled(Accordion)(({ theme }) => ({
  backgroundColor: "transparent !important",
  boxShadow: "none",

  "& .MuiAccordionSummary-content.Mui-expanded":{
    margin: "0"
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:991.9px)": {},
}));
