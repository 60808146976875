import { Formik } from "formik"
import * as Yup from "yup";
import { PyButton, PySelect, ToastMessage, TOASTER_TYPE } from "../../../../../../components"
import { PyInput } from "../../../../../../components"
import { useQuery } from "@tanstack/react-query";
import { getAllBanks, verifyAccountNumber } from "../../../../../../services/api";
import React from "react";

const validationSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank name is required, select a bank"),
    accountNumber: Yup.string().required("Account number is required"),
    // accountName: Yup.string().required("Account name is required"),
    cardType: Yup.string().required("Card type is required"),
    brand: Yup.string().required("Card brand is required, select card brand"),
    bin: Yup.string().required("Enter first 6 digits of your card").max(6, "Maximum 6 digits"),
});

interface IProps {
    getVerificationDetails: (data: any) => void,
    handleClose: () => void
}

export const AddPaymentMethod: React.FC<IProps> = ({
    getVerificationDetails,
    handleClose
}) => {
    const [verifyData, setVerifyData] = React.useState<any>(null);
    const { data: bank_data, isLoading: banks_loading } = useQuery({
        queryKey: ["AllBanks"],
        queryFn: () => getAllBanks({ country: "Nigeria", gateway: "PAYSTACK" })
    })
    const { data: verify_account_data, isLoading: verify_account_loading, refetch: verify_account_refetch } = useQuery({
        queryKey: ["VerifyAccountNumber"],
        queryFn: () => verifyAccountNumber({
            accountNumber: verifyData?.accountNumber,
            bankCode: verifyData?.bankCode
        }),
        enabled: !!verifyData?.accountNumber && !!verifyData?.bankCode
    })

    React.useEffect(() => {
        if (verifyData) {
            verify_account_refetch();
        }
    }, [verifyData]);

    const allBanks: any[] = bank_data?.data ? bank_data?.data.map((bank: any) => ({
        ...bank,
        value: bank.code
    })) : [];

    const initialValues = {
        bankName: "",
        accountNumber: "",
        accountName: "",
        cardType: "",
        brand: "",
        bin: "",
        country: "Nigeria",
        verificationRequired: true,
    };

    const cardTypes = [{ name: "Debit", value: "debit" }, { name: "Credit", value: "credit" }]

    const cardBrands = [{ name: "Mastercard", value: "mastercard" }, { name: "VISA", value: "visa" }, { name: "Verve", value: "verve" }]

    const getBankCode = (bankName: string) => {
        if (!bankName) return;
        const selectedBank = allBanks.filter((bank: any) => {
            return bankName === bank.name;
        });
        return selectedBank[0].code;
    };

    const handleVerifyAccountNumber = async (
        bankName: string,
        accountNumber: string
    ) => {
        if (!bankName) {
            ToastMessage(TOASTER_TYPE.ERROR, "Select bank");
        }
        if (accountNumber.length < 10) return;

        const data = {
            accountNumber: accountNumber,
            bankCode: getBankCode(bankName),
        };

        setVerifyData(data);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                const data = { ...values, accountName: verify_account_data.data?.account_name }
                console.log(data);
                getVerificationDetails(data)
                handleClose()
            }}
        >
            {(
                {
                    isValid,
                    touched,
                    errors,
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    values,
                }
            ) => (
                <form className="flex flex-col gap-4 w-full p-14 rounded-2xl" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-1">
                        <h3 className="text-[1.25rem] text-py_black-950 font-semibold">Add payment method</h3>
                        <p className="text-[1rem] text-py_black-600 font-normal">Add a payment method to continue</p>
                    </div>

                    <PySelect
                        fullWidth
                        useValue={false}
                        hasObjects
                        label="Bank"
                        name="bankName"
                        id="bankName"
                        selectableObjects={allBanks}
                        value={values.bankName}
                        onChange={handleChange}
                    />

                    <div className="flex flex-col w-full gap-1">
                        <PyInput
                            fullWidth
                            type="text"
                            label="Account number"
                            placeholder="Enter your bank account number"
                            name="accountNumber"
                            value={values.accountNumber}
                            onChange={(e) => {
                                handleChange(e)
                                handleVerifyAccountNumber(values.bankName, e.target.value)
                            }}
                        />

                        {verify_account_data && verify_account_data.data && values.accountNumber.length === 10 && (
                            <div className="text-sm text-py_green_2-600 font-medium">
                                {verify_account_data.data?.account_name}
                            </div>
                        )}
                    </div>


                    <PySelect
                        fullWidth
                        useValue
                        hasObjects
                        label="Card type"
                        name="cardType"
                        value={values.cardType}
                        selectableObjects={cardTypes}
                        onChange={handleChange}
                    />

                    <PySelect
                        fullWidth
                        useValue
                        hasObjects
                        label="Card scheme"
                        name="brand"
                        value={values.brand}
                        selectableObjects={cardBrands}
                        onChange={handleChange}
                    />

                    <PyInput
                        fullWidth
                        type="text"
                        label="BIN"
                        placeholder="Enter first 6 digits of your card"
                        name="bin"
                        value={values.bin}
                        onChange={handleChange}
                    />



                    <div className="flex gap-4 items-center">
                        <PyButton variant="SecondaryOutline" extraClass="!w-[104px]" click={handleClose}> Cancel </PyButton>
                        <PyButton type="submit" extraClass="!w-[104px]"> Add </PyButton>
                    </div>
                </form>
            )}
        </Formik>
    )
}