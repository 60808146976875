import { styled } from "@mui/material";

export const PointCardBox = styled("div")(({ theme }) => ({
  "& .card": {
    padding: "1rem",
  },

  "& .title": {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#150200",
  },

  "& .balance": {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#0081D4",
  },

  "& .inputHolder": {
    "& input": {
      borderWidth: "0 0 0.5px 0",
      borderColor: "#FF5349",
      padding: "0.5rem 0.875rem",
      transition: "0.3s all ease-in-out",

      "&:focus-visible": {
        borderWidth: "0 0 1px 0",
        borderColor: "#FF5349",
        outline: "none",
        boxShadow: "2px 2px 8px 0px rgba(237, 33, 21, 0.20)",
        backgroundColor: "#fff",
      },

      "&::placeholder": {
        color: "#C4C4C4",
        fontSize: "0.625rem",
        fontWeight: 500,
      },

      "&:required": {
        borderBottom: "1.5px solid #dc3545",
      },

      // hide arrow up and down in input type=number
      "&::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
        display: "none",
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },

  "& .errorMessage": {
    fontWeight: 500,
    fontSize: "0.75rem",
    display: "block",
    color: "#dc3545",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {},
}));
