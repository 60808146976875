import { styled } from "@mui/material";

export const PaymentSuccessBox = styled("div")(({ theme }) => ({
  padding: "3.5rem 6.375rem",
  background: "#ffffff",
  borderRadius: "1rem",
  border: "1px solid #E6E6E7",

  "& .paymentContainer": {
    "& .headerContainer": {
      width: "75%",
      margin: "0 auto",
      textAlign: "center",

      "& .title": {
        color: "#0F0F10",
        fontSize: "1.25rem",
        marginBottom: "0.875rem",
        fontWeight: 600,
      },

      "& .details,  .detailId": {
        color: "#5A5B60",
        fontSize: "1rem",
        fontWeight: 400,
        marginBottom: "1.35rem",
      },

      "& .detailId": {
        color: "#847F7F",
      },
    },

    "& .transactDetailsGroup": {
      display: "flex",
      flexDirection: "column",
      gap: "1.25rem 0",
      margin: "0 0 1.5rem",
      padding: "1.25rem 1.5rem",
      border: "1px solid #E6E6E7",
      borderRadius: "1.5rem",

      "& .detailsList": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "0.875rem",
        color: "#847F7F",
        fontWeight: 400,

        "& .name": {
          // fontWeight: 500,
        },

        "& .value": {
          fontWeight: 400,
        },

        "& .totalAmount": {
          fontSize: "0.875rem",
          color: "#0F0F10",
          fontWeight: 600,
        },
      },
    },
  },

  // Screen width 991.9px
  "@media (max-width:991.9px)": {
    padding: "2rem 2.5rem",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {
    padding: "1.5rem",

    "& .paymentContainer": {
      margin: "1.5rem 0",
      padding: "1rem",

      "& svg": {
        width: "65px",
      },

      "& .title": {
        fontSize: "1rem",
      },

      "& .details": {
        fontSize: "0.875rem",
      },

      "& .transactDetailsGroup": {
        "& .detailsList": {
          fontSize: "0.875rem",
        },
      },
    },
  },
}));
