import React from "react";
import { StripePaymentForm } from "../../../../../../components";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

interface IProps {
  handleCreateCardToken?: (arg: any) => void;
  handleClose: () => void;
  gateWayDetails: any;
  userDetails: any
}

export const LoadStripe: React.FC<IProps> = ({
  handleCreateCardToken,
  handleClose,
  gateWayDetails,
  userDetails
}) => {
  const stripePromise = loadStripe(gateWayDetails.publicKey);

  return (
    <Elements stripe={stripePromise}>
      <StripePaymentForm
        userDetails={userDetails}
        stripePromise={stripePromise}
        handleClose={handleClose}
        handleCreateCardToken={handleCreateCardToken}
      />
    </Elements>
  );
};
