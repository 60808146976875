import { TAccordionBox } from "./styles";
import { AccordionSummary, AccordionDetails, Checkbox } from "@mui/material";
import React from "react";
import { BlackCaretDownIcon } from "../../../../assets/svg";

export const TransparentAccordion: React.FC<IProps> = ({
  hasIcon = false,
  icon,
  title,
  subtitle,
  children,
  hasCheckbox = true,
  handleCheck,
  checkState,
  isOrderSummary = false,
}) => {
  const [expand, setExpand] = React.useState<boolean>(children ? true : false);

  const toggleCardExpand = () => setExpand(!expand);

  return (
    <TAccordionBox expanded={expand} onChange={toggleCardExpand}>
      <div className="headerSection">
        <AccordionSummary
          expandIcon={<BlackCaretDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="!px-0 !min-h-fit !mb-4"
        >
          <div className="headerContainer">
            <h3 className="text-[1.25rem] text-py_black-950 font-semibold tracking-[-0.48px] md:text-[1.5rem]">{title}</h3>
          </div>
        </AccordionSummary>
      </div>

      <AccordionDetails className="!p-0">
        <section className="">{children}</section>
      </AccordionDetails>
    </TAccordionBox>
  );
};

interface IProps {
  hasIcon?: boolean;
  icon?: React.SVGProps<SVGSVGElement>;
  title: any;
  subtitle?: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
  hasCheckbox?: boolean;
  handleCheck?: any;
  checkState?: boolean;
  isOrderSummary?: boolean;
}
