import { styled } from "@mui/material";

export const EarnedPointBox = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  flexWrap: "wrap",
  backgroundColor: "#ffffff",
  borderRadius: "0.5rem",
  border: "1px solid #E6E6E7",
  padding: "1rem",

  "& .earnedPoint": {
    padding: "0.22rem 0.44rem",
    fontSize: "0.625rem",
    fontWeight: 500,
    color: "#ffffff",
    background: "#00AE97",
    borderRadius: "4px",
    display: "inline-flex",
    alignItems: "center",
    gap: "0 0.2rem",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {},
}));
