import { WebsiteBaseLayout } from "../components/Layout/Base";
import { PyButton } from "../../../components";
import { WWalletIcon, CartIcon, TravelIcon } from "../../../assets/svg";
import PricingImg1 from "../../../assets/images/pricing-img-1.svg";
import PricingImg2 from "../../../assets/images/pricing-img-2.svg";
import PricingImg3 from "../../../assets/images/pricing-img-3.svg";
import PricingImg4 from "../../../assets/images/pricing-img-4.svg";

export const Pricing = () => {
  return (
    <WebsiteBaseLayout>
      <header className="h-full py-16 border-b border-py_black-100 bg-white lg:py-30">
        <div className="container flex h-full flex-wrap !px-5 md:!px-11 lg:!px-16">
          <div className="w-full flex items-center justify-center mb-12">
            <div className="text-center mx-auto lg:w-7/12">
              <h2 className="text-py_black-950 font-semibold text-[2rem] leading-[110%] tracking-[-1px] !mb-5 lg:text-[3.125rem]">
                Clear and simple pricing for businesses big and small
              </h2>
              <p className="mb-12 text-py_black-700 font-normal lg:text-[1.25rem]">
                Pay-as-you-go, zero setup or hidden fees.
              </p>
              <PyButton>Contact Sales</PyButton>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section className="bg-white relative bg-no-repeat py-20">
          <div className="container !px-5 md:!px-11 lg:!px-16">
            <div className="text-center mb-20 mx-auto lg:w-7/12">
              <h3 className="text-py_black-950 font-semibold text-[1.75rem] lg:text-[2.25rem]">
                What you get
              </h3>
              <p className="text-py_black-700 font-normal">
                Get a bundle of out-of-the box features to get you started on
                your growth journey.
              </p>
            </div>

            <div className="flex flex-col gap-20">
              <div className="flex flex-wrap gap-20">
                <div className="w-full flex items-center !order-2 lg:w-[calc((100%/2)-(80px/2))] lg:!order-1">
                  <div className="lg:mr-10">
                    <h4 className="text-[1.5rem] text-py_black-950 font-semibold leading-[120%] tracking-[-0.48px] !my-5 md:tracking-[-0.56px] text-[1.75rem]">
                      Delight your customers with more ways to shop and pay at
                      checkout
                    </h4>

                    <ul className="flex flex-col !gap-4">
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Bring your own payment gateway
                      </li>

                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Pay with points, coupons, cashbacks and cards
                      </li>

                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Preview of checkout earnings to incentivise conversion
                        and grow cart value
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="w-full flex items-center justify-center !order-1 lg:w-[calc((100%/2)-(80px/2))] justify-end lg:!order-2">
                  <img
                    src={PricingImg1}
                    alt=" Boost your sales with personalised offerings built from rich
                    profiles and custom attributes"
                    width={470}
                    height={270}
                    className="w-full md:w-[400px] xl:w-[470px]"
                  />
                </div>
              </div>

              <div className="flex flex-wrap gap-20">
                <div className="w-full flex items-center justify-center lg:w-[calc((100%/2)-(80px/2))]">
                  <img
                    src={PricingImg2}
                    alt="Transform casual shoppers into loyal customers by using
                  our promotional campaigns and loyalty capabilities."
                    width={470}
                    height={270}
                    className="w-full md:w-[400px] xl:w-[470px]"
                  />
                </div>

                <div className="w-full !order-1 lg:w-[calc((100%/2)-(80px/2))] lg:!order-1">
                  <div className="lg:ml-10">
                    <h4 className="text-[1.5rem] text-py_black-950 font-semibold leading-[120%] tracking-[-0.48px] my-5 md:tracking-[-0.56px] text-[1.75rem]">
                      Create and manage a winning loyalty programmes
                    </h4>

                    <ul className="flex flex-col !gap-4">
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Coalition programmes
                      </li>

                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Multi-tiered and paid membership programmes
                      </li>

                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Publishing of loyalty membership programmes to the
                        Paycurra mobile wallet app
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap gap-20">
                <div className="w-full !order-2 lg:w-[calc((100%/2)-(80px/2))] lg:!order-1">
                  <div className="lg:mr-10">
                    <h4 className="text-[1.5rem] text-py_black-950 font-semibold leading-[120%] tracking-[-0.48px] my-5 md:tracking-[-0.56px] text-[1.75rem]">
                      Create and plan effective promotional campaigns
                    </h4>

                    <ul className="flex flex-col !gap-4">
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Flexible rules engine
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Multiple coupon types: discount, stamps, free gifts,
                        cash back
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Usage tracking and monitoring
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Publishing of promotion-linked rewards to the Paycurra
                        mobile wallet app
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="w-full flex items-center justify-center !order-1 lg:w-[calc((100%/2)-(80px/2))] justify-end lg:!order-2">
                  <img
                    src={PricingImg3}
                    alt="Integrate your existing payment gateway accounts:
                  Paystack, Stripe, and more to your Paycurra wallet."
                    width={470}
                    height={270}
                    className="w-full md:w-[400px] xl:w-[470px]"
                  />
                </div>
              </div>

              <div className="flex flex-wrap gap-20">
                <div className="w-full flex items-center justify-center lg:w-[calc((100%/2)-(80px/2))]">
                  <img
                    src={PricingImg4}
                    alt="Transform casual shoppers into loyal customers by using
                  our promotional campaigns and loyalty capabilities."
                    width={470}
                    height={270}
                    className="w-full md:w-[400px] xl:w-[470px]"
                  />
                </div>

                <div className="w-full order-1 lg:w-[calc((100%/2)-(80px/2))] lg:order-1">
                  <div className="lg:ml-10">
                    <h4 className="text-[1.5rem] text-py_black-950 font-semibold leading-[120%] tracking-[-0.48px] my-5 md:tracking-[-0.56px] text-[1.75rem]">
                      Gain insight into customer behaviour and manage
                      engagements
                    </h4>

                    <ul className="flex flex-col !gap-4">
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Measure and track customer engagement KPIs
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Review consumer checkout sessions and manage disputes
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Personalise rewards based on customer insights
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Recover abandoned carts with personalised emails and
                        linked coupon offerings tailored to convert
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-py_black-950 py-20">
          <div className="container !px-5 md:!px-11 lg:!px-16">
            <div className="w-full mx-auto text-center mb-14 lg:w-[70%]">
              <h4 className="text-py_black-100 text-[2.25rem] font-semibold leading-[110%] tracking-[-0.8px] mb-6 xl:w-9/12 xl:mx-auto">
                Build customer engagement experiences that are tailored to your
                industry
              </h4>
              <p className="text-white text-[1rem] font-normal leading-[120%] xl:w-10/12 xl:mx-auto">
                Whether you are a fintech insurgent, an incumbent bank or a
                retail store, integrate with the Paycurra Commerce Platform
                today for solutions to help you attract and retain customers
                with personalised offerings.
              </p>
            </div>

            <div className="flex flex-wrap !gap-5">
              <div className="w-full rounded-[1rem] bg-primary-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-primary-300">
                  <CartIcon />
                </div>

                <h6 className="text-[1.125rem] text-py_black-950 !mb-3 font-medium">
                  Ecommerce and Retail
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Give rewards on first visit and on birthdays, incentivise
                  based on cart attributes, increase order value with realtime
                  recommendations and use gamification to drive repeat
                  behaviours.
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_green_2-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_green_2-300">
                  <WWalletIcon />
                </div>
                <h6 className="text-[1.125rem] text-py_black-950 !mb-3 font-medium">
                  Banks and Fintechs
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Award customers points based on account balance, transaction
                  attributes, or card usage. And create winning Cashback loyalty
                  programmes to incentivise targeted payment options (e.g.
                  BNPL).
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_blue-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_blue-300">
                  <TravelIcon />
                </div>

                <h6 className="text-[1.125rem] text-py_black-950 !mb-3 font-medium">
                  Travel and Hospitality
                </h6>
                <p className="text-py_black-700 leading-[120%]">
                  Increase repeat business with a tiered system targeting
                  diverse customer segments. Build rules offering free hotel
                  stays or collaborate with partners in travel industry for
                  flight class upgrades.
                </p>
              </div>
            </div>

            <div className="flex justify-center !gap-5 mt-14">
              <PyButton>Get started</PyButton>
              <PyButton
                extraClass="!text-white !border-white"
                variant="Secondary"
              >
                Book a demo
              </PyButton>
            </div>
          </div>
        </section>
      </main>
    </WebsiteBaseLayout>
  );
};
