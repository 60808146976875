import {
  PaycurraBg,
  ToastMessage,
  TOASTER_TYPE,
  PyButton,
} from "../../../../components";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  enrollForLoyalty,
  getAllEnrolledLoyalty,
} from "../../../../services/api";
import Lottie from "lottie-react";
import SuccessLottie from "../../../../assets/lottie/97240 success.json";
import ErrorLottie from "../../../../assets/lottie/Animation - 1713527600375.json";
import HurrayLottie from "../../../../assets/lottie/Animation - 1713527603542.json";
import LoadingLottie from "../../../../assets/lottie/Animation - 1713527606102.json";
import * as React from "react";
import { getSessionData } from "../../../../config";
import { ICartItem, IMerchantDetails } from "../../../../types/interfaces";

export const EnrollForLoyalty: React.FC<IProps> = ({
  setIsEnrollmentDone,
  userDetails,
  merchantDetails,
}) => {
  const [operationState, setOperationState] =
    React.useState<TOpState>("FetchingLoyalty");
  const { data } = useQuery({
    queryKey: ["AllEnrolledLoyalty"],
    queryFn: () =>
      getAllEnrolledLoyalty({
        state: "Enrolled",
      }),
    onSuccess: (data: any) => {
      if (data.status === 200) {
        // console.log(data);
        if (data.data && data.data.data.length === 0) {
          setOperationState("EnrollingForLoyalty");
          setTimeout(() => handleEnrollment(), 2000);
          return;
        }

        if (data.data && data.data.data.length >= 1) {
          setOperationState("AlreadyEnrolled");
          setTimeout(() => setIsEnrollmentDone(true), 3000);
          return;
        }
      }
    },
    onError: () => {
      setOperationState("FailedLoyalty");
    },
    staleTime: Infinity,
  });
  const onEnrollForLoyalty = useMutation({
    mutationKey: ["EnrollForLoyalty"],
    mutationFn: enrollForLoyalty,
    onSuccess: (data: any) => {
      console.log("data", data)
      // Enrollment already done
      if (data?.response?.status === 409) {
        setIsEnrollmentDone(true)
        return;
      }

      if (data.status === 200 || data.status === 201) {
        setOperationState("EnrolledForLoyalty");
        setTimeout(() => setIsEnrollmentDone(true), 10000); // Buy time for Latency
      } else {
        setOperationState("FailedLoyalty");
      }
    },
    onError: (error: any) => {
      console.log("error", error)
      setOperationState("FailedLoyalty");
    },
  });

  const handleEnrollment = () => {
    const sessionData = getSessionData();
    const selectedLoyalty =
      sessionData.cart.length > 1
        ? getMostValuableLoyalty(sessionData.cart)
        : sessionData.cart[0].loyaltyPromotion;

    const loyaltyPayload = {
      walletUniqueReferenceNumber: sessionData.wURN,
      merchantUniqueReferenceNumber: sessionData.tenant,
      consumerUniqueReferenceNumber: sessionData.conURN,
      loyaltyProgrammeUniqueReferenceNumber:
        selectedLoyalty.loyaltyProgrammeUniqueReferenceNumber,
      tierUniqueReferenceNumber: selectedLoyalty.loyaltyTierReferenceNumber,
      tierName: selectedLoyalty.loyaltyTierName,
      firstName: userDetails.given_name,
      lastName: userDetails.family_name,
      dateOfBirth: "2024-04-19T15:05:19.180Z",
      minimumPayout: selectedLoyalty.minimumPayout,
      pointsToValue: selectedLoyalty.pointsToValue,
      bonusPoints: selectedLoyalty.bonusPoints,
      attributes: [
        {
          key: "",
          value: "",
        },
      ],
      agreement: {
        termsAndConditions: true,
        dataUse: true,
      },
    };

    onEnrollForLoyalty.mutate({ ...loyaltyPayload } as any);
  };

  const getMostValuableLoyalty = (cart: ICartItem[]) => {
    return cart[0].loyaltyPromotion
    // let holdValue: any;
    // // 3 items
    // /**
    //  * 0 - 1
    //  * 1 - 2
    //  */
    // cart.forEach((item, index) => {
    //   if (index > 0) {
    //     holdValue = parseFloat(
    //       (cart[index - 1].loyaltyPromotion as any)?.rewardEffectValue
    //     );

    //     if (
    //       holdValue >
    //       parseFloat((cart[index].loyaltyPromotion as any)?.rewardEffectValue)
    //     ) {
    //     } else if (
    //       holdValue ===
    //       parseFloat((cart[index].loyaltyPromotion as any)?.rewardEffectValue)
    //     ) {
    //     } else {
    //     }
    //   }
    // });

    // // for (let i = 0; i < cart.length; i++) {
    // //   const cartItem = cart[i];

    // // }
  };

  const handleProceedToCheckout = () => {
    setIsEnrollmentDone(true);
  };

  // console.log("data", data);

  return (
    <PaycurraBg>
      <div className="w-full h-[calc(100vh-275px)] d-flex justify-center items-center">
        <div className="py-5 w-10/12 min-h-[320px] flex items-center rounded-[1rem] bg-white md:border md:border-py_black-100 md:py-10 md:w-[480px] md:min-h-[400px] lg:w-[520px] lg:min-h-[450px]">
          <div className="text-center px-8 mx-auto lg:px-4 lg:w-8/12">
            <Lottie
              className={`mx-auto d-block !mb-10 
              ${
                operationState === "FetchingLoyalty"
                  ? "w-[50px] h-[50px] md:w-[80px] md:h-[80px]"
                  : "w-[100px] h-[100px] md:w-[200px] md:h-[200px]"
              }
              `}
              animationData={
                operationState === "FetchingLoyalty" ||
                operationState === "EnrollingForLoyalty"
                  ? LoadingLottie
                  : operationState === "AlreadyEnrolled"
                  ? SuccessLottie
                  : operationState === "EnrolledForLoyalty"
                  ? HurrayLottie
                  : operationState === "FailedLoyalty"
                  ? ErrorLottie
                  : LoadingLottie
              }
              loop={true}
            />

            <h3 className="text-[1rem] mb-1 font-semibold text-py_black-950">
              {(operationState === "FetchingLoyalty" ||
                operationState === "EnrollingForLoyalty") && (
                <>All good things are coming your way!</>
              )}
              {operationState === "AlreadyEnrolled" && (
                <>
                  You already have a loyalty programme from{" "}
                  {merchantDetails?.company?.companyName}
                </>
              )}
              {operationState === "EnrolledForLoyalty" && (
                <>
                  You’ve been successfully enrolled on a loyalty programme and a
                  set of rewards from {merchantDetails?.company?.companyName}
                </>
              )}
              {operationState === "FailedLoyalty" && (
                <>Oops! It’s not you, it’s us!</>
              )}
            </h3>

            <p className="text-[0.875rem] font-normal text-py_black-950 md:text-[0.9rem]">
              {(operationState === "FetchingLoyalty" ||
                operationState === "EnrollingForLoyalty") && (
                <>
                  You're being enrolled on the best loyalty programme from{" "}
                  {merchantDetails?.company?.companyName}
                </>
              )}
              {operationState === "AlreadyEnrolled" && (
                <>
                  You already have a loyalty programme from{" "}
                  {merchantDetails?.company?.companyName}
                </>
              )}
              {operationState === "EnrolledForLoyalty" && (
                <>
                  Proceed to explore and apply the loyalty points and rewards to
                  your purchase
                </>
              )}
              {operationState === "FailedLoyalty" && (
                <>
                  We failed to enroll you on a loyalty programme and a set of
                  rewards from {merchantDetails?.company?.companyName}
                </>
              )}
            </p>

            {operationState === "FailedLoyalty" && (
              <div className="flex flex-col !gap-5 mt-10">
                <PyButton click={handleEnrollment}>Try again</PyButton>
                <PyButton click={handleProceedToCheckout} variant="Secondary">
                  Proceed to Checkout
                </PyButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </PaycurraBg>
  );
};

interface IProps {
  setIsEnrollmentDone: React.Dispatch<React.SetStateAction<boolean>>;
  userDetails: any;
  merchantDetails: IMerchantDetails;
}

type TOpState =
  | "FetchingLoyalty"
  | "AlreadyEnrolled"
  | "EnrollingForLoyalty"
  | "EnrolledForLoyalty"
  | "FailedLoyalty";
