import PaycurraLogo from "../../../../assets/logo/logo-paycurra.svg";
import { PyButton } from "../../../../components";
// import * as React from "react";
import { CloseCircleIcon, MenuIcon } from "../../../../assets/svg";
import { Link } from "react-router-dom";
import {
  HOME_URL,
  DEVELOPER_TOOLS_URL,
  PRICING_URL,
  LOGIN_URL,
} from "../../../../utils/routes.url";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

export const Nav = () => {
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    const menuRef = document.getElementById("mob_menu");
    if (menuRef) menuRef.classList.toggle("show_menu");
  };

  return (
    <nav className="!pt-10 !pb-5 border-b bg-white relative !px-5 lg:!py-6">
      <div className="container flex items-center gap-5 !px-0 lg:gap-6 xl:gap-10">
        <Link
          to={HOME_URL}
          className="inline-flex flex-col w-[80px] relative z-[1000] md:w-[103px] lg:w-[110px] xl:w-[130px]"
        >
          <img
            src={PaycurraLogo}
            width={130}
            height={42}
            alt="Paycurra logo"
            className="w-[80px] md:w-[103px] lg:w-[110px] xl:w-[130px]"
          />
          <small className="ms-auto text-py_black-950 text-[0.65rem] font-['Cabin'] mt-[-0.5rem] lg:text-[0.875rem]">
            Checkout
          </small>
        </Link>

        <ul
          id="mob_menu"
          className="bg-white invisible opacity-0 absolute top-0 right-0 left-0 flex flex-col h-[0px] gap-6 !px-5 pt-[100px] pb-16 transition ease-in-out duration-300 delay-150 md:!h-fit md:!px-0 md:pt-0 md:pb-0 md:!relative md:!opacity-100 md:!gap-7 md:!items-center md:!flex-row md:!visible"
        >
          <IconButton onClick={toggleMobileMenu} className="!p-0 !absolute !h-fit !w-fit !inline-flex right-[1.25rem] top-[40px] md:!hidden">
            <CloseCircleIcon />
          </IconButton>
          <li className="">
            <a href={`${window.location.origin}/#features`}>Features</a>
          </li>
          <li className="">
            <Link to={"#"}>Developer Tools</Link>
          </li>
          <li className="">
            <Link to={PRICING_URL}>Pricing</Link>
          </li>

          <div className="flex fleex-col gap-3 items-center mt-16 md:hidden">
            <PyButton
              extraClass="!py-[8px] !px-4 !text-[0.875rem]"
              variant="Primary"
            >
              Sign up
            </PyButton>
            <PyButton
              extraClass="!border-0 !py-2 !px-4 !text-[0.875rem]"
              variant="Secondary"
              click={() => navigate(LOGIN_URL)}
            >
              Log in
            </PyButton>
          </div>
        </ul>

        <div className="inline-flex items-center ml-auto">
          <div className="hidden gap-3 items-center md:flex ml-auto">
            <PyButton
              extraClass="md:!py-2 !px-4 !text-[0.875rem] xl:!text-[1rem]"
              variant="Primary"
            >
              Sign up
            </PyButton>
            <PyButton
              extraClass="!border-0 md:!py-2 !px-4 !text-[0.875rem] xl:!text-[1rem]"
              variant="Secondary"
              click={() => navigate(LOGIN_URL)}
            >
              Log in
            </PyButton>
          </div>

          <div
            onClick={toggleMobileMenu}
            className="ml-3 cursor-pointer md:hidden"
          >
            <MenuIcon />
          </div>
        </div>
      </div>
    </nav>
  );
};
