import { useLocation, useNavigate } from "react-router-dom"
import { getQueryParams } from "../../utils/helper"
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { verifyTransaction } from "../../services/api";
import axios from "axios";
import Lottie from "lottie-react";
import SuccessLottie from "../../assets/lottie/97240 success.json";
import ErrorLottie from "../../assets/lottie/Animation - 1713527600375.json";
import LoadingLottie from "../../assets/lottie/Animation - 1713527606102.json";
import { PaycurraBg } from "../../components";

type TOpState = "InProgress" | "Error" | 'Success'

export const VerifyPayment = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = getQueryParams(location.search);
    const reference = queryParams.get("reference");
    const [operationState, setOperationState] = React.useState<TOpState>("InProgress");
    const onVerifyTransaction = useMutation(verifyTransaction, {
        onSuccess: (res: any) => {
            if (res?.status === 201 || res?.status === 200) {
                setOperationState('Success')
                localStorage.removeItem("P_D")
                // VTR - Verify Transaction Response
                localStorage.setItem("VTR", JSON.stringify(res.data))
                setTimeout(() => {
                    navigate("/")
                }, 2000);
            } else {
                setOperationState('Error')
            }
        },
        onError: (error: unknown) => {
            if (axios.isAxiosError(error)) {
                setOperationState('Error')
                console.log(error);
                // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
            }
        },
    });

    React.useEffect(() => {
        const verifyTransaction = () => {
            const paystackResponse = JSON.parse(localStorage.getItem("P_D") as string);

            const body = {
                paymentUniqueReferenceNumber: paystackResponse.paymentUniqueReferenceNumber,
                paymentItemUniqueReferenceNumber: paystackResponse.paymentItemUniqueReferenceNumber,
                paymentMethodVerification: true,
                paymentGatewayReferenceNumber: reference,
                customerUniqueReferenceNumber: paystackResponse.customerUniqueReferenceNumber,
            };

            onVerifyTransaction.mutate(body as any)
        }

        verifyTransaction()
    }, [])




    return (
        <PaycurraBg>
            <div className="w-full h-[calc(100vh-275px)] d-flex justify-center items-center">
                <div className="py-5 w-10/12 min-h-[320px] flex items-center rounded-[1rem] bg-white md:border md:border-py_black-100 md:py-10 md:w-[480px] md:min-h-[400px] lg:w-[520px] lg:min-h-[450px]">
                    <div className="text-center px-8 mx-auto lg:px-4 lg:w-8/12">
                        <Lottie
                            className={`mx-auto d-block !mb-10 
                ${operationState === "InProgress"
                                    ? "w-[50px] h-[50px] md:w-[80px] md:h-[80px]"
                                    : "w-[100px] h-[100px] md:w-[200px] md:h-[200px]"
                                }
                `}
                            animationData={
                                (operationState === 'InProgress' && LoadingLottie) ||
                                (operationState === 'Success' && SuccessLottie) ||
                                (operationState === 'Error' && ErrorLottie)
                            }
                            loop={true}
                        />

                        <h3 className="text-[1rem] mb-1 font-semibold text-py_black-950">
                            {operationState === "InProgress" && (
                                <>Please wait, verification in progress...</>
                            )}
                            {operationState === "Success" && (
                                <>Payment method verified</>
                            )}
                            {operationState === "Error" && (
                                <>Oops! It’s not you, it’s us!</>
                            )}
                        </h3>

                        <p className="text-[0.875rem] font-normal text-py_black-950 md:text-[0.9rem]">
                            {(operationState === "InProgress") && (
                                <> We are verifying your payment method  </>
                            )}
                            {(operationState === "Error") && (
                                <> Payment method verification failed  </>
                            )}
                        </p>

                        {/* {operationState === "FailedLoyalty" && (
                            <div className="flex flex-col !gap-5 mt-10">
                                <PyButton click={handleEnrollment}>Try again</PyButton>
                                <PyButton click={handleProceedToCheckout} variant="Secondary">
                                    Proceed to Checkout
                                </PyButton>
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </PaycurraBg>
    )
}