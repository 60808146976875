import { Nav } from "./Nav";
import { Footer } from "./Footer";
import React from "react";

export const WebsiteBaseLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      <Nav />
      {children}
      <Footer />
    </>
  );
};
