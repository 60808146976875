import React from "react";
import { RewardRuleEffects } from "../../../../types";
import { NumericFormat } from "react-number-format";
import { convertPriceToNaira } from "../../../../utils/helper";
import {
  NQtyIcon,
  PQtyIcon,
  GiftBagIcon,
  RemoveItemIcon,
} from "../../../../assets/svg";
import { ICartItem } from "../../../../types/interfaces";
import { IconButton } from "@mui/material";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { getSessionData, setSessionData } from "../../../../config";
import { discardStoreCartItem } from "../../../../services/api";

interface ICartProps {
  cartItem: ICartItem;
  cartItems: ICartItem[];
  gateWayDetails: any;
  updateCartItems: React.Dispatch<React.SetStateAction<any[] | ICartItem[]>>;
  amendSession: UseMutationResult<unknown, unknown, void, unknown>;
}

export const CartCard: React.FC<ICartProps> = ({
  cartItem,
  cartItems,
  gateWayDetails,
  updateCartItems,
  amendSession,
}) => {


  const onDiscardStoreCartItem = useMutation({
    mutationKey: ["DiscardStoreCartItem"],
    mutationFn: discardStoreCartItem,
  });

  const calculatePriceAndQty = (price: any, qty: any, type: "old" | "new") => {
    const priceAndQty = Number(price) * Number(qty);
    // if (type === "new") {
    //   // Checking for reward Value limit - Discount applied must not exceed it
    //   return discountAmount > cartItem.rewardEffectValueLimit
    //     ? rewardEffectValueLimit
    //     : computedValue;
    // }

    return priceAndQty;
  };

  const getRewardText = (reward_type: string) => {
    let rewardText: string = "";

    if (reward_type === RewardRuleEffects.AddFreeItem) {
      rewardText = "Buy 2 get 1 free";
    }

    if (reward_type === RewardRuleEffects.AddDiscount) {
      rewardText = ` ${cartItem.discountPercent}% off`;
    }

    return `${rewardText}`;
  };

  const updateCartAndAmendSession = (updatedCart: ICartItem[]) => {
    const sessionData: any = getSessionData();
    const updatedSessionData = {
      ...sessionData,
      cart: updatedCart,
    };
    setSessionData(updatedSessionData);
    updateCartItems(updatedCart);
    // Amend session
    amendSession.mutate({
      wURN: sessionData.wURN,
      conURN: sessionData.conURN,
    } as any);
  };

  const handleRemoveItem = (selected_item: ICartItem) => {
    const updatedCart = cartItems.filter(
      (item) => item.sku !== selected_item.sku
    );

    const discardPayload = {
      productCatalogueVariantUniqueReferenceNumber:
        selected_item.productVariantURN,
      productCatalogueItemUniqueReferenceNumber: selected_item.productURN,
    };

    // DELETE Item in the store
    onDiscardStoreCartItem.mutate({ ...discardPayload } as any);

    updateCartAndAmendSession(updatedCart);
  };

  const handleAdjustQuantity = (
    selected_item: ICartItem,
    action_type: "increase" | "decrease"
  ) => {
    const updatedCart = cartItems.map((item) => {
      if (item.sku === selected_item.sku) {
        item = {
          ...item,
          quantity:
            action_type === "increase" ? item.quantity + 1 : item.quantity - 1,
          newQuantity:
            action_type === "increase"
              ? item.newQuantity + 1
              : item.newQuantity - 1,
        };

        return item;
      }

      return item;
    });

    console.log(updatedCart);

    updateCartAndAmendSession(updatedCart);
  };

  return (
    <div
      className={`relative border rounded-[1rem] bg-white !p-4 ${cartItem.isOutOfStock ? "!border-primary-600" : "!border-py_black-100"
        }`}
    >
      {cartItem.isOutOfStock && (
        <IconButton
          onClick={() => handleRemoveItem(cartItem)}
          className="!p-[0.25rem] !rounded-full !absolute right-[-15px] top-[-16px]"
        >
          <RemoveItemIcon />
        </IconButton>
      )}
      <div className="flex w-full !gap-4 relative">
        {cartItem.isOutOfStock && (
          <div className="bg-primary-600 text-white text-[0.75rem] p-[0.25rem] px-[0.5rem] rounded-[100px] absolute md:p-[0.4rem] md:px-[0.65rem] md:text-[0.875rem]">
            {(cartItem.availableStockQuantity as number) >= 1
              ? "Adjust quantity"
              : "Out of stock"}
          </div>
        )}
        <div className="rounded-[11.1px] min-w-[48px] w-[48px] h-[48px] overflow-hidden xl:min-w-[80px] xl:w-[80px] xl:h-[80px]">
          <img
            src={cartItem.image}
            width={80}
            height={80}
            className="w-[80px] h-[80px]"
            alt="..."
          />
        </div>

        <div className="flex flex-wrap !gap-2 w-[calc(100%-64px)] border-b border-py_black-100 border-solid !pb-3 lg:flex-nowrap xl:w-[calc(100%-96px)]">
          <div className={"flex flex-col !gap-3 w-full lg:w-[calc(75%-4px)]"}>
            <p className={"text mb-0 truncate"}>{cartItem?.name}</p>

            <div className="qtyContainer d-flex gap-2 align-items-center">
              <div className="qText">Qty:</div>
              <div className="qtyHolder d-inline-flex align-items-center gap-2">
                <IconButton
                  disabled={
                    (cartItem.isOutOfStock &&
                      (cartItem?.availableStockQuantity as number) === 0) ||
                      cartItem.quantity === 1
                      ? true
                      : false
                  }
                  className="!p-0"
                  onClick={() => handleAdjustQuantity(cartItem, "decrease")}
                >
                  <NQtyIcon />
                </IconButton>
                <div className="qty">{cartItem?.newQuantity}</div>
                <IconButton
                  className="!p-0"
                  onClick={() => handleAdjustQuantity(cartItem, "increase")}
                  disabled={
                    cartItem.isOutOfStock &&
                      (cartItem?.availableStockQuantity as number) === 0
                      ? true
                      : false
                  }
                >
                  <PQtyIcon />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="flex flex-col text-right w-full lg:w-[calc(25%-4px)]">
            <div className="text-[1rem] text-py_black-600 font-semibold">
              <NumericFormat
                value={convertPriceToNaira(
                  calculatePriceAndQty(
                    cartItem?.newPrice,
                    cartItem?.quantity,
                    "new"
                  )
                )}
                displayType={"text"}
                thousandSeparator={true}
                prefix={gateWayDetails?.currencySymbol}
              />
            </div>

            {cartItem?.newPrice !== cartItem?.price && (
              <del className="text-[0.875rem] text-py_black-700 font-normal">
                <NumericFormat
                  value={convertPriceToNaira(
                    calculatePriceAndQty(
                      cartItem?.price,
                      cartItem?.quantity,
                      "old"
                    )
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={gateWayDetails?.currencySymbol}
                />
              </del>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full !gap-4 !pt-3">
        {cartItem?.rewardType && (
          <div className="inline-flex items-center !gap-1 rounded-[100px] bg-py_green_1-300 !py-1 !px-2">
            <GiftBagIcon />

            <div className="text-[0.875rem] text-py_black-950 font-medium">
              {getRewardText(cartItem?.rewardType)}
            </div>
          </div>
        )}

        <div className="text-[1rem] text-py_black-600 font-semibold ml-auto">
          <NumericFormat
            value={convertPriceToNaira(
              calculatePriceAndQty(
                cartItem?.newPrice,
                cartItem?.quantity,
                "new"
              )
            )}
            displayType={"text"}
            thousandSeparator={true}
            prefix={gateWayDetails?.currencySymbol}
          />
        </div>
      </div>
    </div>
  );
};
