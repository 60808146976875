import { createTheme } from "@mui/material/styles";

const primaryColor = "#FF5349";

export let theme = createTheme({
  typography: {
    fontFamily: ["Bricolage Grotesque", "Cabin", "sans-serif"].join(","),
  },

  palette: {
    primary: {
      main: primaryColor,
      contrastText: "#ffffff",
    },

    secondary: {
      main: "#0F0F10",
    },

    error: {
      light: "#FA7777",
      main: "#F71C1C",
      dark: "#d31717",
    },
  },

  // palette: {
  //   background: {
  //     default: "#e5e5e5",
  //   },

  //   primary: {
  //     light: "rgba(255, 83, 73,0.85)",
  //     main: "#FF5349",
  //     //   dark: "",
  //     //   contrastText: "#ffffff",
  //   },
  // },

  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0)",
            // backgroundColor: "rgba(255, 83, 73, 0.3)", // Change this color as needed
          },
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});
